import { useAppSelector } from "../redux/store";
import { ROLE, STATUS } from "../utils/constants";
import { TRow } from "../utils/types";

/*
 Оператор может:
 1. Редактировать только свои заявки
 2. Редактировать до момента согласования
 3. Удалять только свои заявки до момента согласования

 Старший оператор может:
 1. Редактировать только свои заявки
 2. Редактировать до момента согласования
 3. Удалять только свои заявки до момента согласования
 4. Согласовывать заявки, в которых он выставлен ответственным
 5. Менять статус заявки до конца месяца или до определенной даты, которую можем согласовать с продажами по тем заявкам , в которых он выставлен ответственным

 Администратор может:
 1. Редактировать любые заявки
 2. Редактировать до момента согласования
 3. Удалять любые заявки
 4. Согласовывать любые заявки
 5. Менять статус заявки до 5 числа следующего месяца
 */

/** Роли пользователей */
function UseRole( row: TRow ) {
   const user = useAppSelector( state => state.auth.user )
   const {
      author_id,
      status,
      closed,
      confirmed, 
      responsible_id,
      responsible_agent_id,
   } = row

   // const currentMount = new Date().getMonth()
   // const orderMount = new Date( createdat ).getMonth()

   const isOperator = user?.role === ROLE.OPERATOR
   const isOperator_s = user?.role === ROLE.OPERATOR_S
   const isOperator_s_a = user?.role === ROLE.OPERATOR_S_A
   const isAll = responsible_id === responsible_agent_id
   const isAdmin = user?.role === ROLE.ADMIN

   /** Владелец заявки */
   const isAuthorId = author_id === user?.id
   /** Ответственный заявки */
   const isResponsible = responsible_id === user?.id
   const isResponsible2 = responsible_agent_id === user?.id

   const isStatusWatch = status === STATUS.WATCH
   const isStatusApprove = status === STATUS.APPROVE
   const isStatusNoApprove = status === STATUS.NO_APPROVE

   /** Дубликаты согласованной заявки */
   const isConfirmedDuplicate = (isStatusWatch || isStatusNoApprove) && confirmed


   /** Заблокировать согласование по истечению 1 месяца для старшего оператора:
    * - если это оператор или старший оператор и текущая дата больше даты создания заявки;
    */
   // const isOperatorSDisabledAfterOneMonth = ( currentMount > orderMount )
   const isOperatorSDisabledAfterOneMonth = false

   /** Заблокировать заявку для редактирования:
    * - если это (оператор или старший оператор) и не автор заявки;
    * - или заявка согласована;
    * - или заявка закрыта;
    */
   const isRowDisabled =
   !isAdmin && (( isOperator || isOperator_s  ) && !isAuthorId
      || !isStatusWatch
      || closed
      || ( isOperator_s && isOperatorSDisabledAfterOneMonth ))

   /** Показать кнопку "Сохранить данные":
    * - если это автор заявки и заявка ещё на рассмотрении;
    * - или заявка не заблокирована;
    * - и не вышел срок согласования для старшего оператора;
    */
   const isShowBtnSave = ( isAuthorId && isStatusWatch || !isRowDisabled )

   /**
    * Показать кнопки согласования:
    * - если это ответственный за заявку или админ;
    * */
   const isShowBtnsConfirm =
      ( isAdmin || isResponsible || isResponsible2 )
      &&  !closed  
      &&  !isConfirmedDuplicate 
      &&  !( isOperator_s && isOperatorSDisabledAfterOneMonth )


   /** Показать блок с кнопками заявки:
    * - если кнопка "Сохранить данные" и кнопки согласования не скрыты;
    * - или это оператор;
    * - или заявка закрыта;
    */
   const isShowBtns = !isShowBtnSave && !isShowBtnsConfirm || closed


   return {
      isOperator,
      isOperator_s,
      isOperator_s_a,
      isAdmin,

      isStatusWatch,
      isStatusApprove,
      isStatusNoApprove,
      isAll,

      isAuthorId,
      isResponsible,
      isRowDisabled,
      isOperatorSDisabledAfterOneMonth,

      isShowBtnSave,
      isShowBtns,
      isShowBtnsConfirm,
   }
}


export default UseRole;
